.aside-menu {
  z-index: 1;
  position: fixed;
  left: 0;
  top: 3rem;
  transform: translateX(-100%);
  transition: transform 0.3s ease 0.2s;

  &.visible {
    transform: translateX(0);
  }
}
