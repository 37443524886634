@import "../../../node_modules/bulma/sass/utilities/mixins.sass";

.prompts-container {
  position: fixed;
  bottom: 16px;
  z-index: 1000;
  left: 16px;

  @include from($tablet) {
    left: 50%;
  }
}
